<template>
    <div class="kit-order-setting-wrapper">
        <div class="kit-order-setting-wrapper__inner" v-if="kitOrderSettings">
            <h1 class="mb-32">{{ $t('kitOrderSetting.title') }}</h1>
            <div>
                <h2 class="mb-32">{{ $t('kitOrderSetting.cancellation') }}</h2>
                <CRToggle class="mb-40" v-model:checked="kitOrderSettings.isUserCancellationDisabled">
                    {{ $t('kitOrderSetting.disableUserCancellation') }}
                </CRToggle>
                <CRInput
                    type="number"
                    class="mb-25"
                    v-model="kitOrderSettings.cancellationTermHours"
                    :label="$t('kitOrderSetting.cancellationTermInHours')"
                    :placeholder="$t('kitOrderSetting.enterCancellationTermInHours')"
                    :errors="errors?.cancellationTermHours"
                    :helpText="$t('kitOrderSetting.amountOfHoursBeforeDelivery')"
                    @onChange="$filters.clearError(errors, 'cancellationTermHours')"
                />
            </div>
            <div>
                <h2 class="mb-32">{{ $t('kitOrderSetting.change') }}</h2>
                <CRToggle class="mb-40" v-model:checked="kitOrderSettings.isUserChangesDisabled">
                    {{ $t('kitOrderSetting.disableChange') }}
                </CRToggle>
                <CRInput
                    type="number"
                    class="mb-25"
                    v-model="kitOrderSettings.changesAppliedTermHours"
                    :label="$t('kitOrderSetting.termForChangeHours')"
                    :placeholder="$t('kitOrderSetting.enterTermForChangeHours')"
                    :errors="errors?.changesAppliedTermHours"
                    :helpText="$t('kitOrderSetting.timeAfterWhichChange')"
                    @onChange="$filters.clearError(errors, 'changesAppliedTermHours')"
                />
                <CRInput
                    type="number"
                    class="mb-25"
                    v-model="kitOrderSettings.deliveryDateChangesTermHours"
                    :label="$t('kitOrderSetting.deliveryDateChangeLimitHours')"
                    :placeholder="$t('kitOrderSetting.enterDeliveryDateChangeLimitHours')"
                    :errors="errors?.deliveryDateChangesTermHours"
                    :helpText="$t('kitOrderSetting.howManyHoursBeforeStartOfDelivery')"
                    @onChange="$filters.clearError(errors, 'deliveryDateChangesTermHours')"
                />
            </div>
            <CRButton @click="updateKitOrderSetting">
                {{ $t('buttons.save') }}
            </CRButton>
        </div>
    </div>
</template>

<script>
    import CRToggle from '@/components/Common/Buttons/CRToggle.vue';
    import CRInput from '@/components/Common/Inputs/CRInput';
    import CRButton from '@/components/Common/Buttons/CRButton';
    import { KitOrderSettingApi } from '@/api';
    import { TYPE } from 'vue-toastification';

    export default {
        name: 'KitOrderSettings',
        components: { CRToggle, CRInput, CRButton },
        data() {
            return {
                kitOrderSettings: null,
                errors: {},
            };
        },

        methods: {
            async updateKitOrderSetting() {
                try {
                    const response = await KitOrderSettingApi.update(this.kitOrderSettings.id, this.kitOrderSettings);
                    this.kitOrderSettings = response.data;

                    this.$filters.toast(this.$t('kitOrderSetting.title') + this.$t('otherSettings.updated'), {
                        type: TYPE.SUCCESS,
                    });
                } catch (error) {
                    this.errors = error.errors;
                }
            },

            async fetchSettings() {
                try {
                    const response = await KitOrderSettingApi.get();
                    this.kitOrderSettings = response.data;
                } catch (error) {
                    this.$filters.toast(error.message);
                }
            },
        },

        async mounted() {
            await this.fetchSettings();
        },
    };
</script>

<style lang="scss" scoped>
    .kit-order-setting-wrapper {
        @include column-align-start-justify-start;
        flex-wrap: wrap;

        padding: 15px 0 25px;

        .kit-order-setting-wrapper__inner {
            max-width: 633px;
            width: 100%;

            h1 {
                display: none;

                @include media($lg) {
                    display: block;
                }
            }
        }
    }
</style>
